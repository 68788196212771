        <template>
            <v-card v-if="client" @click="goTo" class="hover-shadow pa-2" outlined>
                <v-row align="center">
                    <v-col cols="3">
                        <v-avatar size="75" tile>
                            <v-img v-if="clientImg" :src="`${baseUrl}${clientImg}`" />
                            <v-icon v-else class="py-6" color="gold" size="75">mdi-warehouse</v-icon>
                        </v-avatar>
                    </v-col>
                    <v-col cols="9">
                        <v-row dense>
                            <v-col cols="10" class="client-name text-truncate">
                                {{ client.name }}
                            </v-col>
                            <v-col cols="2" align="right">
                                <v-btn fab outlined x-small @click.stop="emitEdit">
                                    <v-icon>mdi-pencil</v-icon>
                                </v-btn>
                            </v-col>
                            <v-col cols="5" class="client-info">
                                <v-icon>mdi-pin-outline</v-icon>
                                Tareas <span class="info-number">{{ tasksQuantity }}</span>
                            </v-col>
                            <v-col cols="7" class="client-info">
                                <v-icon>mdi-file-document-outline</v-icon>
                                Documentos <span class="info-number">{{ documentsQuantity }}</span>
                            </v-col>
                            <v-col cols="5" class="client-info">
                                <v-icon>mdi-clipboard-text-outline</v-icon>
                                Actas <span class="info-number">{{ meetingsQuantity }}</span>
                            </v-col>
                            <v-col cols="7" class="client-info">
                                <v-icon>mdi-signal-cellular-outline</v-icon>
                                Estados Financiero <span class="info-number">{{ financialsQuantity }}</span>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-card>
        </template>

<script>
export default {
    name: "ClientCard",
    props: {
        client: {
            type: Object,
            default: null,
        },
        icon: {
            type: String,
            default: 'mdi-warehouse'
        }
    },
    computed: {
        tasksQuantity(vm = this) {
            let quantity = vm.client.manager_tasks && vm.client.manager_tasks.length || 0
            return quantity < 10 ? `0${quantity}` : quantity
        },
        documentsQuantity(vm = this) {
            let quantity = vm.client.documents && vm.client.documents.length || 0
            return quantity < 10 ? `0${quantity}` : quantity
        },
        meetingsQuantity(vm = this) {
            let quantity = vm.client.meeting_records && vm.client.meeting_records.length || 0
            return quantity < 10 ? `0${quantity}` : quantity
        },
        financialsQuantity(vm = this) {
            let quantity = vm.client.financial_statements && vm.client.financial_statements.length || 0
            return quantity < 10 ? `0${quantity}` : quantity
        },
        baseUrl() {
            return process.env.VUE_APP_BASE_URL || null
        },
        clientImg(vm = this) {
            return vm.client?.filepath || null
        },
    },
    methods: {
        goTo() {
            const vm = this
            vm.$router.push({ name: 'clients.details', params: { id: vm.client.id } })
        },
        emitEdit() {
            const vm = this
            vm.$emit('edit')
        },
    }
}
</script>

<style scoped>
.client-name {
    font-weight: bold;
    font-size: 14px;
    font-family: Arial, Helvetica, sans-serif;
}

.client-info {
    color: #333333;
    font-size: 11px;
}

.info-number {
    font-size: 13px;
    font-weight: bold;
}
</style>