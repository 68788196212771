<template>
    <v-container>

        <!-- HEADER -->
        <v-row class="mt-5">
            <v-col cols="12">
                <div class="title-section">
                    CLIENTES
                </div>
            </v-col>
            <v-col cols="6">
                <v-btn v-if="isAdmin || isAmbUser" class="normal-btn" color="primary" depressed
                    @click="showCreateDialog">
                    <v-icon left>mdi-plus-circle-outline</v-icon>
                    Agregar Cliente
                </v-btn>
            </v-col>
            <v-col cols="6">
                <kimsa-text-field placeholder="Buscar" append-icon="mdi-magnify" outlined dense solo flat
                    @change="search = $event" :loading="loadingSearch" />
            </v-col>
        </v-row>

        <!-- BODY -->
        <v-row class="mb-5" v-if="clients.length > 0">
            <v-col cols="6" lg="3" v-for="(client, i) in clients" :key="i">
                <client-card :client="client" @edit="showEditClient(client.id)" />
            </v-col>
        </v-row>
        <v-row class="mb-5" v-else>
            <v-col>
                <v-alert outlined type="info" dense color="accent">
                    Sin Clientes aún
                </v-alert>
            </v-col>
        </v-row>

        <!-- COMPONENTS -->
        <create-client-dialog @deleted="loadClients" @success="loadClients" ref="creator" />

    </v-container>
</template>

<script>
import KimsaTextField from "@/components/common/inputs/KimsaTextField";
import Axios from "@/utils/Axios";
import ClientCard from "@/components/client/ClientCard";
import CreateClientDialog from "@/components/client/CreateClientDialog";
export default {
    name: "Clients",
    components: { CreateClientDialog, ClientCard, KimsaTextField },
    data() {
        return {
            search: '',
            clients: [],
            loadingSearch: false,
        }
    },
    async mounted() {
        const vm = this
        await vm.loadClients()
    },
    methods: {
        async loadClients() {
            const vm = this
            vm.loadingSearch = true
            await Axios.post(`clients-data`, { search: vm.search }).then(res => {
                // console.log('res loadClients', res)
                vm.clients = res.data.result.clients
            }).catch(er => {
                console.log('error loadClients', er)
            })

            vm.loadingSearch = false
        },
        showCreateDialog() {
            const vm = this
            vm.$refs.creator.show()
        },
        showEditClient(clientId) {
            const vm = this
            vm.$refs.creator.loadClient(clientId)
        },
    },
    watch: {
        search(val) {
            if (val.length === 0 || val.length >= 3) this.loadClients()
        }
    }
}
</script>

<style scoped>
.title-section {
    color: var(--v-primary-base);
}
</style>